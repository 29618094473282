import React from 'react';
import Image from '../components/Image';
import { isInGame } from '../lib/commons';

const Header = ({
  meta = {},
  pages = [],
  page = {},
  onChangePage = (p) => {},
}) => {
  const handleClickPage = (p, e) => {
    e.preventDefault();
    onChangePage(p);
  };

  const handleClickShare = (e) => {
    FB.ui({
      method: 'share',
      href: window.location.href,
      quote: meta?.shareQuote,
      hashtag: meta?.shareHashtag,
    }, function(response) {});
  };

  return (
    <header className={`header ${page?.id === 1 ? 'is-home' : ''}`}>
      <a href={isInGame() ? null : 'https://fo4.garena.vn/'} className="header__logo" target="_blank">
        <Image path="logo.png"/>
      </a>
      {page?.id !== 1 && (
        <div className="header__legend animate__animated animate__jackInTheBox">
          VIETNAM<br/>LEGEND
        </div>
      )}
      {!isInGame() && (
        <>
          <a
            href={meta?.downloadLink}
            title="Tải game"
            className="header__download"
            target="_blank"
          >
            <Image path="btn-download-1.png"/>
            <Image path="btn-download-2.png"/>
          </a>
          <a
            href="#"
            title="Chia sẻ"
            className="header__share"
            onClick={e => handleClickShare(e)}
          >
            <Image path="btn-share-1.png"/>
            <Image path="btn-share-2.png"/>
          </a>
        </>
      )}
      {pages?.length && (
        <ul className="header__menu">
          {pages?.map((item, index) => (
            <li key={index} className={`${item?.id === page?.id ? 'active' : ''}`}>
              <a href="#" onClick={e => handleClickPage(item, e)}>
                {item?.name}
              </a>
            </li>
          ))}
        </ul>
      )}
    </header>
  );
};

export default Header;
