import config from './common';

export const meta = {
  downloadLink: 'https://fo4.garena.vn/hoc-vien/d/huong-dan-tai-va-cai-dat-fifa-online-4-tren-pc/',
  shareQuote: 'Khám phá ngay câu chuyện lịch sử và chỉ số của các Vietnam Legends',
  shareHashtag: '#VietnamLegendsFO4',
  delayTransition: 1000,
  bannerLink: 'https://skvn.fo4.garena.vn/',
  bannerImage: `${config?.cdn}/img/banner.png`
};

export const preloadImages = [
  // General
  `${config?.cdn}/img/decor-player-1.png`,
  `${config?.cdn}/img/decor-player-2.png`,
  `${config?.cdn}/img/bg-frame.png`,
  `${config?.cdn}/img/btn-achievement.png`,
  `${config?.cdn}/img/btn-stats.png`,
  `${config?.cdn}/img/banner.png`,

  // KV
  `${config?.cdn}/img/kv/background.jpg`,
  `${config?.cdn}/img/kv/players.png`,
  `${config?.cdn}/img/kv/headline.png`,
  `${config?.cdn}/img/kv/badge.png`,

  // 1998
  `${config?.cdn}/img/1998/video-thumb.jpg`,
  `${config?.cdn}/img/1998/player.png`,
  `${config?.cdn}/img/1998/background.png`,
  `${config?.cdn}/img/1998/statistics-1.png?ver=1.0`,
  `${config?.cdn}/img/1998/name.png`,
  `${config?.cdn}/img/1998/year.png`,

  // 2002
  `${config?.cdn}/img/2002/video-thumb.jpg`,
  `${config?.cdn}/img/2002/player.png`,
  `${config?.cdn}/img/2002/background.png`,
  `${config?.cdn}/img/2002/statistics.png`,
  `${config?.cdn}/img/2002/name.png`,
  `${config?.cdn}/img/2002/year.png`,

  // 2007
  `${config?.cdn}/img/2007/video-thumb.jpg`,
  `${config?.cdn}/img/2007/player.png`,
  `${config?.cdn}/img/2007/background.png`,
  `${config?.cdn}/img/2007/statistics.png`,
  `${config?.cdn}/img/2007/name.png`,
  `${config?.cdn}/img/2007/year.png`,

  // 2008
  `${config?.cdn}/img/2008/video-thumb.jpg`,
  `${config?.cdn}/img/2008/player.png`,
  `${config?.cdn}/img/2008/background.png`,
  `${config?.cdn}/img/2008/statistics.png`,
  `${config?.cdn}/img/2008/name.png`,
  `${config?.cdn}/img/2008/year.png`,

  // 2018
  `${config?.cdn}/img/2018/video-thumb.jpg`,
  `${config?.cdn}/img/2018/player.png`,
  `${config?.cdn}/img/2018/background.png`,
  `${config?.cdn}/img/2018/statistics.png`,
  `${config?.cdn}/img/2018/name.png`,
  `${config?.cdn}/img/2018/year.png`,
];

export const pages = [
  { id: 1, name: 'Home', path: '/' },
  { id: 2, name: '1998', path: '/1998' },
  { id: 3, name: '2002', path: '/2002' },
  { id: 4, name: '2007', path: '/2007' },
  { id: 5, name: '2008', path: '/2008' },
  { id: 6, name: '2018', path: '/2018' },
];

export const players = [
  {
    id: 1,
    pageId: 2,
    video: `${config?.cdn}/img/1998/video.webm`,
    videoThumb: `${config?.cdn}/img/1998/video-thumb.jpg`,
    number: '8',
    name: `${config?.cdn}/img/1998/name.png`,
    avatar: `${config?.cdn}/img/1998/player.png`,
    background: `${config?.cdn}/img/1998/background.png`,
    stats: `${config?.cdn}/img/1998/statistics-1.png?ver=1.0`,
    year: `${config?.cdn}/img/1998/year.png`,
    moments: `
      NGUYỄN HỒNG SƠN - QBV 1998 và 2000 khiến hàng triệu con tim thổn thức<br/>
      Ở những năm cuối thập niên 90, nhắc tới bóng đá Việt Nam chúng ta không thể không nói tới Nguyễn Hồng Sơn - một tiền vệ hào hoa, thi đấu vô cùng kĩ thuật, từng là bộ não của đội tuyển quốc gia. Đỉnh cao sự nghiệp của Hồng Sơn có lẽ chính là năm 1998, khi ở giải đấu Tiger Cup năm đó, Hồng Sơn đã ghi dấu ấn đậm nét khi trở thành người ghi nhiều bàn thắng nhất cho đội tuyển Việt Nam với 3 bàn thắng. Trong đó, chúng ta sẽ không thể nào quên được bàn thắng nhân đôi cách biệt trong trận bán kết gặp Thái Lan, tạo tiền đề để Việt Nam thắng chung cuộc 3-0 ở trận đấu đó và giành vị trí á quân tại Tiger Cup 1998. Cá nhân Hồng Sơn cũng đoạt hàng loạt danh hiệu cá nhân khi được bầu là “cầu thủ xuất sắc nhất Tiger Cup 1998”, 2 quả bóng vàng Việt Nam năm 1998 và 2000, và đặc biệt nhất chính là Cầu thủ xuất sắc nhất tháng của Châu Á vào tháng 8 năm 1998 - điều mà chưa cầu thủ Việt Nam nào đạt được.
    `,
    achievements: [
      'Quả bóng vàng Việt Nam 1998 và 2000',
      'Cầu thủ xuất sắc nhất Tiger Cup 1998',
      'Cầu thủ xuất sắc nhất châu Á tháng 8 năm 1998',
      'Huy chương bạc Tiger Cup năm 1998',
      'Huy chương bạc SEA Games năm 1995 và 1999',
    ],
  },
  {
    id: 2,
    pageId: 3,
    video: `${config?.cdn}/img/2002/video.webm`,
    videoThumb: `${config?.cdn}/img/2002/video-thumb.jpg`,
    number: '10',
    name: `${config?.cdn}/img/2002/name.png`,
    avatar: `${config?.cdn}/img/2002/player.png`,
    background: `${config?.cdn}/img/2002/background.png`,
    stats: `${config?.cdn}/img/2002/statistics.png`,
    year: `${config?.cdn}/img/2002/year.png`,
    moments: `
      LÊ HUỲNH ĐỨC - Mẫu tiền đạo toàn diện của bóng đá Việt Nam<br/>
      Khi nói tới một tiền đạo toàn diện - hiếm có trong lịch sử bóng đá Việt Nam, với thể hình vượt trội, nhưng vẫn có thể khéo léo vượt qua nhiều hậu vệ và thực hiện những cú sút trái phá làm tung lưới đối thủ ở mọi khoảng cách. Có lẽ NHM nước nhà sẽ nghĩ ngay tới một huyền thoại của bóng đá VN đã từng làm mưa làm gió trong màu áo CLB hay ĐTQG. Đúng vậy, đó chính là Lê Huỳnh Đức, một tượng đài trong ngôi đền huyền thoại của bóng đá Việt Nam. Ba quả bóng vàng Việt Nam vào các năm 1995, 1997 và năm 2002 cũng đủ để nói lên sự xuất sắc của cựu tiền đạo đội tuyển Việt Nam ở cuối thập niên 90 và đầu thập niên 2000. Huỳnh Đức còn là lá cờ tiên phong cho bóng đá Việt Nam khi trở thành cầu thủ đầu tiên được đầu quân cho CLB nước ngoài, giúp bóng đá nước nhà được bạn bè quốc tế biết đến rộng rãi hơn.
    `,
    achievements: [
      'Quả bóng vàng Việt Nam 1995, 1997, 2002',
      'Vua phá lưới giải vô địch quốc gia 1996, 1997',
      'Kỷ lục tham dự 5 lần Tiger CUP liên tiếp',
    ],
  },
  {
    id: 3,
    pageId: 4,
    video: `${config?.cdn}/img/2007/video.webm`,
    videoThumb: `${config?.cdn}/img/2007/video-thumb.jpg`,
    number: '7',
    name: `${config?.cdn}/img/2007/name.png`,
    avatar: `${config?.cdn}/img/2007/player.png`,
    background: `${config?.cdn}/img/2007/background.png`,
    stats: `${config?.cdn}/img/2007/statistics.png`,
    year: `${config?.cdn}/img/2007/year.png`,
    moments: `
      VŨ NHƯ THÀNH - Trung vệ “trăm năm có một” của Bóng đá Việt Nam<br/>
      Nhiều người gọi anh là Trung vệ xuất sắc nhất mà bóng đá Việt nam từng sản sinh ra. Sinh ra trong gia đình có gen bóng đá, thế nên khả năng chơi bóng của Vũ Như Thành đã được đánh giá rất cao ngay từ khi còn nhỏ. Thế nhưng, cái tên Vũ Như Thành mới được NHM Việt Nam biết tới rộng rãi chính là những năm 2007-2008. Ở sân chơi cao nhất cấp châu lục Asian Cup năm 2007, Vũ Như Thành như một lá chắn thép bảo vệ khung thành khi đội tuyển nằm chung bảng với các đối thủ mạnh nhất như Nhật Bản, Qatar. Đến năm 2008, Như Thành vẫn cho thấy sự chắc chắn, đọc tình huống thông minh của mình ở giải đấu AFF Cup 2008 và góp phần giúp Việt Nam giành bước lên đỉnh Đông Nam Á sau nhiều năm chờ đợi.
    `,
    achievements: [
      'Quả bóng bạc Việt Nam: 2008',
      'Vô địch Giải vô địch bóng đá Đông Nam Á 2008',
    ],
  },
  {
    id: 4,
    pageId: 5,
    video: `${config?.cdn}/img/2008/video.webm`,
    videoThumb: `${config?.cdn}/img/2008/video-thumb.jpg`,
    number: '9',
    name: `${config?.cdn}/img/2008/name.png`,
    avatar: `${config?.cdn}/img/2008/player.png`,
    background: `${config?.cdn}/img/2008/background.png`,
    stats: `${config?.cdn}/img/2008/statistics.png`,
    year: `${config?.cdn}/img/2008/year.png`,
    moments: `
      LÊ CÔNG VINH - Huyền thoại với khoảnh khắc lịch sử<br/>
      Lê Công Vinh, sinh ngày 10/12/1985, là một trong những tượng đài của bóng đá Việt Nam với 83 lần ra sân đi cùng với đó là 51 bàn thắng cho đổi tuyển quốc gia Việt Nam - đều là những kỉ lục mà chưa cầu thủ nào khác chạm tới. Nhắc tới Công Vinh, chúng ta là nhớ tới bàn thắng lịch sử trong trận chung kết AFF Cup 2008 khi đối đầu với Thái Lan tại sân vận động quốc gia Mỹ Đình. Ở phút bù giờ cuối cùng, từ pha tạt bóng của đồng đội, Công Vinh đã chạy chỗ để chọn vị trí và bật cao đánh đầu dũng mãnh dù vấp phải sự truy kèm của đối phương và thế rồi bàn thắng đã tới. Pha ghi bàn đó không chỉ giúp chúng ta chiến thắng người Thái sau 2 lượt trận mà nó còn giúp Việt Nam lên ngôi vô địch AFF cup lần đầu tiên trong lịch sử. Đó chắc hẳn là một trong những khoảnh khắc mà chúng ta không thể nào quên khi nhắc tới tiền đạo người Nghệ An này.
    `,
    achievements: [
      'Quả bóng vàng Việt Nam năm 2004, 2006, 2007',
      'AFC: Huyền thoại Đông Nam Á (2020)',
      'Top 10 cầu thủ ghi bàn nhiều nhất cho đội tuyển quốc gia thế giới tính đến năm 2016',
      'Vô địch Giải vô địch bóng đá Đông Nam Á: 2008',
    ],
  },
  {
    id: 5,
    pageId: 6,
    video: `${config?.cdn}/img/2018/video.webm`,
    videoThumb: `${config?.cdn}/img/2018/video-thumb.jpg`,
    number: '11',
    name: `${config?.cdn}/img/2018/name.png`,
    avatar: `${config?.cdn}/img/2018/player.png`,
    background: `${config?.cdn}/img/2018/background.png`,
    stats: `${config?.cdn}/img/2018/statistics.png`,
    year: `${config?.cdn}/img/2018/year.png`,
    moments: `
      NGUYỄN ANH ĐỨC - Ngôi sao nở muộn của bóng đá Việt Nam<br/>
      Một trong những cây săn bàn cự phách của bóng đá Việt Nam đã sản sinh ra. Nhắc tới Anh Đức, chúng ta nói đến một tiền đạo bền bỉ của bóng đá Việt Nam. Quả bóng vàng năm 2015 đã có khoảng thời gian vô cùng đáng nhớ với đội tuyển Việt Nam ở giải AFF cup 2018 - giải đấu mà Anh Đức là người góp công nhiều nhất cho đội tuyển với 4 bàn thắng dù anh đã bước sang tuổi 33. Ở trận chung kết lượt về, khi Việt Nam đối đầu với Malaysia tại sân vận động quốc gia Mỹ Đình trước sự chứng kiến của gần 4 vạn người hâm mộ nước nhà. Trong pha phối hợp ban bật của các đồng đội ở hành lang cánh trái, bóng được tạt vào bên trong vòng cấm khung thành của Malaysia. Anh Đức đã bình tĩnh xoay lưng và tung cú vô lê bằng chân trái của mình và làm tung lưới đối thủ. Cuối cùng, bàn thắng duy nhất của Anh Đức trong trận chung kết đó đã giúp Việt Nam một lần nữa bước lên đỉnh Đông Nam Á sau một thập kỷ.
    `,
    achievements: [
      'Quả bóng vàng Việt Nam 2015',
      'Quả bóng bạc Việt Nam 2017, 2018',
      'Vô địch Giải vô địch bóng đá Đông Nam Á 2018',
      'Hạng tư môn bóng đá nam ASIAD 2018',
    ],
  },
];
