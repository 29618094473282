import React from 'react';
import Image from './Image';
import Scrollbar from 'react-scrollbars-custom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { isMobile, isInGame } from '../lib/commons';

const DURATION = ['animate__nofast', 1000];

const Player = ({
  meta = {},
  player = {},
  playerView = 0,
  showBanner = false,
  setShowBanner = (b) => {},
  setPlayerView = (i) => {},
  setDisableScroll = (b) => {},
}) => {
  const handleToggleView = (e) => {
    e.preventDefault();
    setPlayerView(val => +(!val));
  };

  const handleClickCloseBanner = (e) => {
    e.preventDefault();
    setShowBanner(val => !val);
  };

  const handleHoverScroll = (e) => {
    if ([...e?.currentTarget?.classList].includes('trackYVisible')) {
      setDisableScroll(true);
    }
  };

  return (
    <div className="player">
      {!isInGame() && (
        <div className={`player__banner ${!showBanner ? 'closed' : ''}`}>
          <a href={meta?.bannerLink} className="player__banner--img" target="_blank">
            <img src={meta?.bannerImage}/>
          </a>
          <a
            href="#"
            className={`player__banner--close ${!showBanner ? 'closed' : ''}`}
            onClick={e => handleClickCloseBanner(e)}
          >
            <Image path="icon-arrow-right.svg"/>
          </a>
        </div>
      )}
      <div className="player__left">
        <TransitionGroup>
          <CSSTransition
            key={playerView}
            timeout={{ enter: 1000, exit: 0 }}
            classNames={{
              enterActive: `cssanimation leZoomIn`,
              exitActive: `animate__animated animate__fadeOutDown ${DURATION[0]}`,
            }}
          >
            <div className="player__view">
              {playerView === 0 ? (
                <TransitionGroup>
                  <CSSTransition
                    key={player?.id}
                    timeout={{ enter: 1000, exit: 0 }}
                    classNames={{
                      enterActive: `cssanimation leZoomIn`,
                      exitActive: `animate__animated animate__fadeOutDown ${DURATION[0]}`,
                    }}
                  >
                    <div className="player__video">
                      {isMobile() ? (
                        <img src={player?.videoThumb}/>
                      ) : (
                        <video autoPlay muted loop>
                          <source src={player?.video} type='video/webm;codecs="vp8, vorbis"'/>
                        </video>
                      )}
                    </div>
                  </CSSTransition>
                </TransitionGroup>
              ) : (
                <>
                  {/* Background */}
                  <TransitionGroup>
                    <CSSTransition
                      key={player?.id}
                      timeout={{ enter: 1000, exit: 0 }}
                      classNames={{
                        enterActive: `cssanimation leZoomIn`,
                        exitActive: `animate__animated animate__fadeOutDown ${DURATION[0]}`,
                      }}
                    >
                      <img src={player?.background} className="player__background"/>
                    </CSSTransition>
                  </TransitionGroup>

                  {/* Number */}
                  <TransitionGroup>
                    <CSSTransition
                      key={player?.id}
                      timeout={{ enter: 1000, exit: 0 }}
                      classNames={{
                        enterActive: `cssanimation leZoomIn`,
                        exitActive: `animate__animated animate__fadeOutDown ${DURATION[0]}`,
                      }}
                    >
                      <div className="player__number">
                        {player?.number}
                      </div>
                    </CSSTransition>
                  </TransitionGroup>

                  {/* Avatar */}
                  <TransitionGroup>
                    <CSSTransition
                      key={player?.id}
                      timeout={{ enter: 1000, exit: 0 }}
                      classNames={{
                        enterActive: `cssanimation leZoomIn`,
                        exitActive: `animate__animated animate__fadeOutDown ${DURATION[0]}`,
                      }}
                    >
                      <div className="player__avatar">
                        <img src={player?.avatar} className="animate__animated animate__pulse animate__infinite animate__slower"/>
                      </div>
                    </CSSTransition>
                  </TransitionGroup>
                </>
              )}
            </div>
          </CSSTransition>
        </TransitionGroup>

        {/* Toogle view button */}
        <TransitionGroup>
          <CSSTransition
            key={playerView}
            timeout={{ enter: DURATION[1], exit: 0 }}
            classNames={{
              enterActive: `animate__animated animate__jackInTheBox ${DURATION[0]}`,
              exitActive: `animate__animated animate__fadeOutDown ${DURATION[0]}`,
            }}
          >
            <a href="#" className="player__toggle" onClick={e => handleToggleView(e)}>
              {playerView === 0 ? (
                <Image path="btn-achievement.png"/>
              ) : (
                <Image path="btn-stats.png"/>
              )}
            </a>
          </CSSTransition>
        </TransitionGroup>
      </div>
      <div className="player__right">
        <div className="player__decor"></div>
        <div className="player__header">
          {/* Name */}
          <TransitionGroup>
            <CSSTransition
              key={player?.id}
              timeout={{ enter: DURATION[1], exit: 2000 }}
              classNames={{
                enterActive: `animate__animated animate__fadeInUp ${DURATION[0]}`,
                exitActive: `animate__animated animate__fadeOutUp ${DURATION[0]}`,
              }}
            >
              <div className="player__name">
                <img src={player?.name}/>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
        {/* Content */}
        <TransitionGroup>
          <CSSTransition
            key={player?.id}
            timeout={{ enter: DURATION[1], exit: DURATION[1] }}
            classNames={{
              enterActive: `animate__animated animate__fadeIn ${DURATION[0]}`,
              exitActive: `animate__animated animate__fadeOut ${DURATION[0]}`,
            }}
          >
            <TransitionGroup>
              <CSSTransition
                key={playerView}
                timeout={{ enter: DURATION[1], exit: DURATION[1] }}
                classNames={{
                  enterActive: `animate__animated animate__fadeIn ${DURATION[0]}`,
                  exitActive: `animate__animated animate__fadeOut ${DURATION[0]}`,
                }}
              >
                <div>
                  {playerView === 0 ? (
                    <div className="player__stats">
                      <img src={player?.stats}/>
                    </div>
                  ) : (
                    <div className="player__info">
                      <Image path="bg-frame.png" className="player__info--frame"/>
                      <div className="player__moments">
                        <div className="player__moments--title">KHOẢNH<br/>KHẮC<br/>LỊCH SỬ</div>
                        <div className="player__moments--content">
                          <Scrollbar
                            noScrollX={true}
                            onMouseOver={e => handleHoverScroll(e)}
                            onMouseLeave={e => setDisableScroll(false)}
                            onTouchStart={e => handleHoverScroll(e)}
                            onTouchEnd={e => setDisableScroll(false)}
                          >
                            <p dangerouslySetInnerHTML={{__html: player?.moments}}></p>
                          </Scrollbar>
                        </div>
                      </div>
                      <div className="player__achievements">
                        <div className="player__achievements--title">DANH HIỆU<br/>TIÊU BIỂU</div>
                        <div className="player__achievements--content">
                          <Scrollbar
                            noScrollX={true}
                            onMouseOver={e => handleHoverScroll(e)}
                            onMouseLeave={e => setDisableScroll(false)}
                            onTouchStart={e => handleHoverScroll(e)}
                            onTouchEnd={e => setDisableScroll(false)}
                          >
                            <ul>
                              {player?.achievements?.map((achievement, index) => (
                                <li key={index}>{achievement}</li>
                              ))}
                            </ul>
                          </Scrollbar>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </CSSTransition>
            </TransitionGroup>
          </CSSTransition>
        </TransitionGroup>
        <div className="player__footer">
          {/* Year */}
          <TransitionGroup>
            <CSSTransition
              key={player?.id}
              timeout={{ enter: 1000, exit: 0 }}
              classNames={{
                enterActive: `cssanimation leZoomIn`,
                exitActive: `animate__animated animate__fadeOutDown ${DURATION[0]}`,
              }}
            >
              <div className="player__year">
                <img src={player?.year}/>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </div>
  );
};

export default Player;
