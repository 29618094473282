import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getCurrentUser } from 'authentication/actions';
import Header from '../Header';
import { meta, pages, players, preloadImages } from '../../config/data';
import { useHistory } from 'react-router';
import { useSwipeable } from 'react-swipeable';
import KeyVisual from '../../components/KeyVisual';
import Player from '../../components/Player';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { isInGame } from '../../lib/commons';

const PageLayout = ({currentUser, location, children, check, getCurrentUser}) => {
  const history = useHistory();
  const [page, setPage] = useState(pages?.[0] || {});
  const [player, setPlayer] = useState({});
  const [loading, setLoading] = useState(false);
  const [disableScroll, setDisableScroll] = useState(false);
  const [playerView, setPlayerView] = useState(1);
  const [isHome, setIsHome] = useState(false);
  const [showBanner, setShowBanner] = useState(true);

  const timerId = useRef(null);

  useEffect(() => {
    return () => {
      clearTimeout(timerId.current);
    }
  }, []);

  useEffect(() => {
    const index = pages?.findIndex(p => p?.path === location?.pathname);
    if (index !== -1) {
      setPage(pages?.[index]);
    }
  }, [location?.pathname]);

  useEffect(() => {
    setLoading(true);
    const index = players?.findIndex(p => page?.id === p?.pageId);
    setPlayer(players?.[index] || {});
    setIsHome(page?.id === 1);
  }, [page]);

  useEffect(() => {
    if (loading) {
      timerId.current = setTimeout(() => {
        setLoading(false);
      }, meta?.delayTransition);
    }
  }, [loading]);

  const handleChangePage = (delta) => {
    const index = pages?.findIndex(m => m?.id === page?.id);
    let nextIndex = index;

    if (delta < 0) {
      nextIndex = (index - 1);
    } else if (delta > 0) {
      nextIndex = (index + 1);
    }

    const nextPage = pages?.[nextIndex];

    if (!!nextPage && !loading && !disableScroll) {
      history.push(`${nextPage?.path}${isInGame() ? '?ingame=1' : ''}`);
    }
  }

  const handleScroll = (e) => {
    handleChangePage(e.deltaY);
  };

  const handleClickPage = (p) => {
    if (loading) {
      return;
    }

    history.push(`${p?.path}${isInGame() ? '?ingame=1' : ''}`);
  };

  const handleSwipe = useSwipeable({
    onSwipedUp: (eventData) => {
      handleChangePage(1);
    },
    onSwipedDown: (eventData) => {
      handleChangePage(-1);
    },
    trackMouse: false,
  });

  return (
    <main {...handleSwipe} id="main" onWheel={e => handleScroll(e)}>
      {preloadImages?.map((image, index) => (
        <img src={image} key={index} className="d-done"/>
      ))}
      <Header
        page={page}
        meta={meta}
        pages={pages}
        onChangePage={p => handleClickPage(p)}
      />
      <TransitionGroup>
        <CSSTransition
          key={isHome}
          timeout={{ enter: 1000, exit: 0 }}
          classNames={{
            enterActive: `cssanimation leZoomInSmall`,
            exitActive: ``,
          }}
        >
          <div className="wrapper">
            {page?.id === 1 && (
              <KeyVisual/>
            )}
            {page?.id !== 1 && !!player?.id && (
              <Player
                meta={meta}
                showBanner={showBanner}
                setShowBanner={setShowBanner}
                isHome={isHome}
                player={player}
                playerView={playerView}
                setPlayerView={setPlayerView}
                setDisableScroll={setDisableScroll}
              />
            )}
          </div>
        </CSSTransition>
      </TransitionGroup>
    </main>
  );
};

const mapDispatchToProps = {
  getCurrentUser
};

const mapStateToProps = (state) => ({
  currentUser: state.currentUser
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageLayout));
