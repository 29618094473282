import React from 'react';
import Image from './Image';

const KeyVisual = () => {
  return (
    <div className="kv">
      <div className="kv__scroll">
        <Image path="icon-scroll-down.svg"/>
      </div>
      <div className="kv__ball animate__animated animate__bounceInUp">
        <Image path="kv/ball.png"/>
      </div>
      <div className="kv__headline cssanimation cssanimation__fast leZoomIn">
        <Image path="kv/headline.png"/>
      </div>
      <div className="kv__lightning">
        <Image path="kv/lightning.png" className="animate__animated animate__flash animate__infinite"/>
      </div>
      <div className="kv__players animate__animated animate__fadeInUp animate__fast">
        <Image path="kv/players.png" className="animate__animated animate__pulse animate__infinite animate__slower"/>
      </div>
      <div className="kv__trophy animate__animated animate__bounceInUp">
        <Image path="kv/trophy.png"/>
      </div>
      <div className="kv__smoke-right">
        <Image path="kv/smoke-right.png"/>
      </div>
      <div className="kv__smoke-left">
        <Image path="kv/smoke-left.png"/>
      </div>
      <div className="kv__badge animate__animated animate__jackInTheBox">
        <Image path="kv/badge.png"/>
      </div>
      <div className="kv__date">
        <Image path="kv/date.png"/>
      </div>
    </div>
  );
};

export default KeyVisual;
